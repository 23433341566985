import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user.type == _vm.UserTypeEnum.Investor && !_vm.loading
    ? _c("div", [
        _c(
          "div",
          {
            staticClass: "banner-bottom",
            style: _vm.drawer && _vm.gs.isMobile() ? "display: none" : "",
          },
          [
            _c(
              VRow,
              { staticClass: "text-part", attrs: { cols: "6", md: "7" } },
              [
                _vm.activated
                  ? _c("p", { staticStyle: { color: "var(--primary)" } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("will_notify_thesis_opportunity")) +
                          " "
                      ),
                    ])
                  : _c("p", { staticStyle: { color: "var(--dark)" } }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("notify_thesis_opportunity")) + " "
                      ),
                    ]),
              ]
            ),
            _c(
              VRow,
              {
                staticClass: "btn-part",
                staticStyle: { "justify-content": "flex-end !important" },
                attrs: { cols: "6", md: "5" },
              },
              [
                _c(VSwitch, {
                  staticClass: "mt-0",
                  attrs: { inset: "", "hide-details": "" },
                  on: { change: _vm.manage_interest },
                  model: {
                    value: _vm.activated,
                    callback: function ($$v) {
                      _vm.activated = $$v
                    },
                    expression: "activated",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }