var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DXAMediaComponent", {
    attrs: { Media: _vm.media_filtered, Header: _vm.Header },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }