import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.Item.Overview
    ? _c(
        VContainer,
        {
          staticClass: "dxa-briefing-card mt-4",
          attrs: { id: "overall", fluid: "", tag: "section" },
        },
        [
          _c(
            "div",
            [
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    { staticClass: "overallColumn", attrs: { cols: "12" } },
                    [
                      _c(
                        "h4",
                        {
                          staticClass: "dxa-briefing-section-header h4",
                          attrs: { "data-test": "Thesis:Overview:H4Overview" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("overview")) + " ")]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "mt-5 text overview-text",
                          class: { "text-expanded": _vm.expanded },
                          staticStyle: { "font-size": "1.2rem" },
                          attrs: { id: "OverviewText" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.Item.Overview[_vm.$i18n.locale]) +
                              " "
                          ),
                        ]
                      ),
                      _vm.Item.Sector
                        ? _c(
                            "p",
                            {
                              staticClass: "mb-0 overview-text",
                              staticStyle: { "font-size": "1.2rem" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("sector") + ": " + _vm.Item.Sector
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.Item.Website
                        ? _c(
                            "p",
                            {
                              staticClass: "overview-text",
                              staticStyle: { "font-size": "1.2rem" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("site") + ": " + _vm.Item.Website
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _c(VRow, [
                _vm.read_more_visible
                  ? _c("div", { staticClass: "mt-4 ml-4" }, [
                      _vm.expanded == false
                        ? _c(
                            "a",
                            {
                              staticStyle: { "text-decoration": "underline" },
                              on: {
                                click: function ($event) {
                                  _vm.expanded = true
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("read_more")))]
                          )
                        : _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.expanded = false
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("read_less")))]
                          ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }