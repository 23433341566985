import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        VContainer,
        { attrs: { id: "briefing", fluid: "", tag: "section" } },
        [
          _c("Header", {
            attrs: {
              Item: {
                Name: _vm.thesis.Name,
                IntroVideo: _vm.thesis.IntroVideo,
                Hero: _vm.thesis.Banner,
              },
              SignedNDA: _vm.signed_nda,
              GoToOptions: _vm.go_to_options(),
            },
            on: { go_to: _vm.scroll },
          }),
          _c("InterestBanner", { attrs: { ThesisId: _vm.thesis.Id } }),
          _c("Overview", {
            attrs: {
              id: "overview",
              Item: _vm.thesis,
              signed_nda: _vm.signed_nda,
            },
          }),
          _vm.highlights_filtered.length > 0
            ? _c("Highlights", {
                attrs: {
                  id: "highlights",
                  Highlights: _vm.highlights_filtered,
                },
              })
            : _vm._e(),
          _vm.thesis.InvestmentConditionsIntro[_vm.$i18n.locale] &&
          _vm.thesis.InvestmentConditionsText[_vm.$i18n.locale]
            ? _c("InvestmentConditions", {
                attrs: {
                  id: "investment-conditions",
                  Item: {
                    InvestmentConditionsIntro:
                      _vm.thesis.InvestmentConditionsIntro[_vm.$i18n.locale],
                    InvestmentConditionsText:
                      _vm.thesis.InvestmentConditionsText[_vm.$i18n.locale],
                  },
                  SignedNDA: _vm.signed_nda,
                },
              })
            : _vm._e(),
          _c("BriefingFiles", {
            attrs: {
              downloadUrl: "thesisfile/download",
              id: "files",
              Files: _vm.files_filtered,
              SignedNDA: _vm.signed_nda,
            },
          }),
          _c("DXAVision", {
            attrs: {
              ThesisId: _vm.thesis.Id,
              MediaType: 0,
              Header: _vm.$t("dxa_vision"),
              id: "dxa-vision",
            },
          }),
          _c("MarketVision", {
            attrs: {
              ThesisId: _vm.thesis.Id,
              MediaType: 1,
              Header: _vm.$t("market_vision"),
              id: "market-vision",
            },
          }),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { margin: "0 auto", display: "block" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }